* {
    box-sizing: border-box;
  }
  
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  
  .main {
    display: flex;
    height: 100vh; /* Teljes képernyős magasság */
    width: 100vw;  /* Teljes képernyős szélesség */
  }
  
  .panel {
    position: relative;
    height: 100%; /* Panel magasság beállítása teljes képernyőre */
    overflow: hidden;
  }
  
  .resizer {
    width: 10px; /* Szélesebb, hogy könnyebb legyen megfogni */
    height: 100%;
    cursor: col-resize; /* Mutatja a méretezés ikont */
    background-color: grey;
    z-index: 1;
  }
  
  .resizer:hover {
    background-color: #3875f6;
  }
  

  .absolute {
    position: absolute;
  }

  .bottom-left {
    right: 30px;
    bottom: 48px;
  }

  .output-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .output-section, .console-section {
    overflow: hidden;
  }
  
  .vertical-resizer {
    height: 10px;
    width: 100%;
    cursor: row-resize;
    background-color: grey;
  }
  
  .vertical-resizer:hover {
    background-color: #3875f6;
  }

  .logo {
    width: 100x;
    height: 100px;
    position: absolute;
    left:45px;
    bottom: 15px;
  }

  .ant-modal {
    min-width: 550px;
  }

  .gap-m {
    gap: 20px
  }

  .gap-s {
    gap: 10px
  }

  .flex {
    display: flex;
  }

  .settings-icon {
    position: absolute;
    bottom:8px;
    left: 8px;
    z-index: 100;
    color: white;
    background: #3875f6;
    padding: 4px;
    border-radius: 100%;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .padding-m {
    padding: 20px;
  }

  .label {
    margin-right: 10px;
  }